import paths from "routes/paths"
import { useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { compose, withHooks, withStores } from "enhancers"

import { Redirect, Route, Switch } from "components"
import Page404 from "pages/auth/Page404"
import Page500 from "pages/auth/Page500"
import SignInPage from "pages/auth/SignIn"

import { HomePage } from "pages/main/home"
import { AccountPage } from "pages/main/account"
import { ProfilePage } from "pages/main/profile"
import { AboutBelivePage } from "pages/main/about-belive"
import { SettingPage } from "pages/main/setting"
import { gql } from "@apollo/client"
import { getRefreshToken, getToken, removeRefreshToken, removeToken, setRefreshToken, setToken } from "api"
import { BenefitPage } from "pages/main/benefit"
import { BenefitDetailPage } from "pages/main/benefit/detail"
import { InsuranceSelectorPage } from "pages/main/benefit/InsuranceSelector"
import { ViewBenefitPage } from "pages/main/benefit/View"
import { ViewDetailBenefitPage } from "pages/main/benefit/ViewDetail"
import { BenefitDetailPackagePage } from "pages/main/benefit/detailPackage"
import { PackageSelectorPage } from "pages/main/benefit/PackageSelector"
import { PreviewBenefitPage } from "pages/main/benefit/PreviewBenefit"
import { PreviewBenefit } from "constants/enums/preview-benefit"
import { PreviewBenefitDetailPage } from "pages/main/benefit/PreviewBenefit/detail"
import { PreviewBenefitDetailPackagePage } from "pages/main/benefit/PreviewBenefit/detailPackage"
import { PreviewInsuranceSelectorPage } from "pages/main/benefit/PreviewBenefit/InsuranceSelector"
import { PreviewPackageSelectorPage } from "pages/main/benefit/PreviewBenefit/PackageSelector"
import userStore from "stores/userStore"
import { isEmpty } from "lodash"

const InitialPages = () => (
  <Switch>
    <Route path={paths.signInPath()} exact component={SignInPage} />
    <Redirect to={paths.signInPath()} />
  </Switch>
)

const MainPages = () => (
  <Switch>
    <Route path={paths.landingPath()} exact component={HomePage} />
    <Route path={paths.accountPath()} exact component={AccountPage} />
    <Route path={paths.profilePath()} exact component={ProfilePage} />
    <Route path={paths.aboutBelivePath()} exact component={AboutBelivePage} />
    <Route path={paths.settingPath()} exact component={SettingPage} />
    <Route path={paths.benefitManagementPath(":id")} exact component={BenefitPage} />
    <Route path={paths.benefitCustomManagementPath(":id")} exact component={BenefitDetailPage} />
    <Route path={paths.benefitCustomManagementEditPath(":id")} exact component={InsuranceSelectorPage} />
    <Route path={paths.benefitPackageManagementPath(":id")} exact component={BenefitDetailPackagePage} />
    <Route path={paths.benefitPackageManagementEditPath(":id")} exact component={PackageSelectorPage} />
    <Route path={paths.viewBenefitPath(":id")} exact component={ViewBenefitPage} />
    <Route path={paths.viewDetailBenefitPath(":id")} exact component={ViewDetailBenefitPage} />
    <Redirect to={paths.landingPath()} />
  </Switch>
)

const GuestPages = () => (
  <Switch>
    <Route path={paths.page404Path()} exact component={Page404} />
    <Route path={paths.page500Path()} exact component={Page500} />
  </Switch>
)

const PreviewPage = () => (
  <Switch>
    <Route path={paths.previewBenefitPath(":id")} exact component={PreviewBenefitPage} />
    <Route path={paths.previewBenefitCustomPath(":id")} exact component={PreviewBenefitDetailPage} />
    <Route path={paths.previewBenefitPackagePath(":id")} exact component={PreviewBenefitDetailPackagePage} />
    <Route path={paths.previewBenefitCustomEditPath(":id")} exact component={PreviewInsuranceSelectorPage} />
    <Route path={paths.previewBenefitPackageEditPath(":id")} exact component={PreviewPackageSelectorPage} />
    <Redirect to={paths.previewBenefitPath()} />
  </Switch>
)

interface RoutesProps {
  initialized: boolean
  isAuthorized: boolean
  isPreview: boolean
}

const Routes = (props: RoutesProps) => {
  const { initialized, isAuthorized, isPreview } = props

  if (isPreview) return <PreviewPage />
  else if (!initialized) return <GuestPages />
  else if (!isAuthorized) return <InitialPages />
  else return <MainPages />
}

export const API = {
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentAppUser {
        id
        email
        employee {
          id
          firstNameTh
          lastNameTh
          firstNameEn
          lastNameEn
          birthDate
          departmentEn
          groupEn
        }
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { useLazyQuery, useUrlParam, useState } = hooks
    const params = useUrlParam()
    const [isLoginWithPortal, setIsLoginWithPortal] = useState(false)
    const [themeName, setThemeName] = useState(null)

    const [fetchCurrentUser, currentUser] = useLazyQuery(API.GET_CURRENT_USER, {
      onCompleted: (data: any) => {
        userStore.setCurrentUser(data.getCurrentAppUser)
        const themeNameFormStorage = localStorage.getItem("themeName")

        // if (themeNameFormStorage !== data.getCurrentAppUser?.employee?.departmentEn) {
        if (themeNameFormStorage !== data.getCurrentAppUser?.employee?.groupEn) {
          const { employee } = data.getCurrentAppUser || {}
          // const correctTheme = employee.departmentEn || "default"
          const correctTheme = employee.groupEn || "default"

          localStorage.setItem("themeName", correctTheme)
          setThemeName(correctTheme)
          window.location.href = paths.homePath()
        } else {
          const correctTheme = themeNameFormStorage || "default"
          setThemeName(correctTheme)
          localStorage.setItem("themeName", correctTheme)
        }

        if (isLoginWithPortal) {
          window.location.href = paths.homePath()
        }
      },
    })

    const { pathname } = useLocation()
    const currentPath = useMemo(() => {
      return pathname.split("/")[1]
    }, [pathname])

    const token = getToken()
    const refreshToken = getRefreshToken()

    const isPreview = currentPath === PreviewBenefit.preview

    useEffect(() => {
      if (isPreview) {
        return
      }

      const { accessToken: accessTokenFromParams, refreshToken: refreshTokenFromParams } = params

      if (accessTokenFromParams && refreshTokenFromParams) {
        removeToken()
        removeRefreshToken()
        setToken(accessTokenFromParams)
        setRefreshToken(refreshTokenFromParams)
        setIsLoginWithPortal(true)
        fetchCurrentUser()
      } else if (token && refreshToken) {
        fetchCurrentUser()
      } else {
        setThemeName("default")
      }
    }, [fetchCurrentUser, isPreview, params, refreshToken, token])

    const hasThemeName = !isEmpty(themeName)
    const isLogin = !!token && !!refreshToken
    const user = isLogin ? currentUser?.data?.getCurrentAppUser || {} : {}
    const hasCurrentUser = !isEmpty(user)

    const initialized = hasThemeName
    const isAuthorized = isLogin && hasCurrentUser

    return {
      initialized,
      isAuthorized,
      isPreview,
    }
  }),
)

export default enhancer(Routes)
