import { linearGradient } from "polished"
import { ReactComponent as EditingImage } from "assets/image/editing__benefit_image.svg"
import { ReactComponent as EmptyImage } from "assets/image/empty_insurance_image.svg"
import { ReactComponent as ViewImage } from "assets/image/view_benefit_image.svg"
import { ReactComponent as BenefitMainIcon } from "assets/icon/benefit_main_icon.svg"
const colorConfigs = {
  "Green/Primary Text": "#A32DC1",
  "Gray/Background ": "#E6E6E6",
  "Text/Dark": "#B39E9A",
  "Text/Primary Pink": "#E31C79",
  "Gray/Card Border, Line": "#A2AAAD",
  "Green/Primary Text2": "#A32DC1",
  "Gray/Secondary Text": "#444444",
  "Gray/Line": "#828282",
  "Gray/Placeholder2": "#5D5D5D",
  "Red/Error Text": "#ED3A53",
  "Warning Text": "#ED8E3A",

  //
  "White / White": "#FFFFFF",
  "Green/Background": "#E6F6EF",
  "Error/Line": "#F6A660",
  "Error/Error Text": "#FE7015",
  "Text/Primary Text": "#000000",
  "Text/Secondary Text": "#333333",
  "Text/Line": "#7C7C7C",
  "Text/Placeholder": "#585858",
  "Text/Background": "#EAEAEA",
  "Gray/Primary Text": "#333333",
  "Text/Disable": "#C5C5C5",
  "Primary/Line": "#A32DC1",
  "Primary/Hover": "#A32DC1",
  "Primary/Primary Text": "#A32DC1",
  "Primary/Background": "#FFE4E5",
  "Primary/Disable": "#FBBDBE",
  Warning: "#EEC11B",
  Info: "#0084FF",
}

const backGroundColorGradient = linearGradient({
  colorStops: [`${colorConfigs["Primary/Primary Text"]} 81.24%`, `${colorConfigs["Error/Line"]} 96.64%`],
  toDirection: "213deg",
  fallback: colorConfigs["Primary/Primary Text"],
})

const configs = {
  colors: colorConfigs,
  gradient: backGroundColorGradient,
  icons: {
    EditingImage: EditingImage,
    EmptyImage: EmptyImage,
    ViewImage: ViewImage,
    BenefitMainIcon: BenefitMainIcon,
  },
}

export default configs
