import { compose, withHooks, withTranslation } from "enhancers"
import { PageContent } from "layouts"
import paths from "routes/paths"
import { MaterialIcon } from "components/common/MaterialIcon"
import { Typography, Box } from "components"
import styled from "styled-components"
import Theme from "theme/custom"
import { gql } from "@apollo/client"
import { formatPhoneNumber } from "utils/helper"

const Menu = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${Theme.colors["Green/Background"]};
  border-radius: 16px;
`

const MenuIcon = styled(MaterialIcon)`
  font-size: 16px;
  margin-right: 4px;
`

const ImageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0px 0px 0px;
`

const Description = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 24px 0px 0px 0px;
`

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0px 0px 24px 0px;
  border-bottom: 1px solid ${Theme.colors["Green/Primary Text2"]};
`

const AboutBeliveComponent = (props: any) => (
  <>
    <PageContent
      title=""
      type="secondary"
      onBack={props.handleClickBack}
      titleBg={Theme.colors["White / White"]}
      backButtonColor={Theme.colors["Green/Primary Text2"]}
      childrenPadding="0px"
    >
      <Box padding="0px 16px">
        <TitleContainer>
          <Typography variant="Header/24" color={Theme.colors["Gray/Primary Text"]}>
            {props.t(".aboutBelive")}
          </Typography>
        </TitleContainer>
        <ImageContainer>
          {props.setting?.images.map((img: any) => (
            <img width="100px" height="100px" alt="" src={img.url}></img>
          ))}
        </ImageContainer>
        <Description>
          <Typography variant="Body/14" color={Theme.colors["Gray/Secondary Text"]}>
            {props.setting?.companyDescription || "-"}
          </Typography>
        </Description>
        <Box mt="56px">
          <Typography variant="h3" color={Theme.colors["Gray/Primary Text"]}>
            {props.t(".contactInformation")}
          </Typography>
        </Box>
        <Menu mt="24px">
          <Box display="flex" flexDirection="row" alignItems="center">
            <MenuIcon name="PhoneInTalk" htmlColor={Theme.colors["Gray/Placeholder2"]} />
            <Typography variant="Body/14" color={Theme.colors["Gray/Placeholder2"]}>
              {props.t(".phone")}
            </Typography>
          </Box>
          <Box mt="4px">
            <Typography variant="h3" color={Theme.colors["Green/Primary Text2"]}>
              {formatPhoneNumber(props.setting?.companyPhoneNumber) || "-"}
            </Typography>
          </Box>
        </Menu>
        <Menu mt="8px">
          <Box display="flex" flexDirection="row" alignItems="center">
            <MenuIcon name="MailOutline" htmlColor={Theme.colors["Gray/Placeholder2"]} />
            <Typography variant="Body/14" color={Theme.colors["Gray/Placeholder2"]}>
              {props.t(".email")}
            </Typography>
          </Box>
          <Box mt="4px">
            <Typography variant="h3" color={Theme.colors["Green/Primary Text2"]}>
              {props.setting?.companyEmail || "-"}
            </Typography>
          </Box>
        </Menu>
      </Box>
    </PageContent>
  </>
)

const API = {
  GET_SETTING: gql`
    query GET_SETTING {
      getSetting {
        id
        companyName
        companyDescriptionTh
        companyDescriptionEn
        companyEmail
        companyPhoneNumber
        images {
          url
          fileName
        }
      }
    }
  `,
}

const enhancer = compose(
  withTranslation({ prefix: "pages.main.about-belive.index" }),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useQuery, useMemo, useDataTranslation } = hooks
    const { loading, data: dataQuery, error } = useQuery(API.GET_SETTING, { fetchPolicy: "network-only" })

    const data = useDataTranslation(dataQuery)

    const setting = useMemo(() => {
      if (loading) {
        return null
      }
      if (error) {
        return null
      }
      return data.getSetting
    }, [loading, data, error])

    const handleClickBack = useCallback(() => {
      paths.accountPath().push()
    }, [])

    return { handleClickBack, setting }
  }),
)

export const AboutBelivePage = enhancer(AboutBeliveComponent)
