import {
  findKey
} from "lodash"
import {
  isClient
} from "common/helper"
import {
  cleanEnv,
  str
} from "envalid"

// console.log("process.env", process.env)

export const envConfig = cleanEnv(process.env, {
  REACT_APP_ENV: str(),
  REACT_APP_VERSION: str(),
  REACT_APP_DEFAULT_LANG: str(),
  NODE_ENV: str(),
})

const config = {
  production: {
    HOST: "https://flex.dentsu-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.dentsu-uat.zennite.co/graphql",
    API: "https://flex-api.dentsu-uat.zennite.co",
  },
  uat: {
    HOST: "https://flex.dentsu-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.dentsu-uat.zennite.co/graphql",
    API: "https://flex-api.dentsu-uat.zennite.co",
  },
  staging: {
    HOST: "https://flex.dentsu-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.dentsu-uat.zennite.co/graphql",
    API: "https://flex-api.dentsu-uat.zennite.co",
  },
  development: {
    HOST: "https://flex.colima.localhost",
    API_ENDPOINT: "https://flex-api.dentsu-uat.zennite.co/graphql",
    API: "https://flex-api.dentsu-uat.zennite.co",
  },
  development2: {
    HOST: "https://flex.colima.localhost",
    API_ENDPOINT: "https://flex-api.dentsu-uat.zennite.co/graphql",
    API: "https://flex-api.dentsu-uat.zennite.co",
  },
}

export const getEnvByHost = () => (isClient ? findKey(config, {
  HOST: window.location.origin
}) : null)

export const env = getEnvByHost() || "production"

console.log("ENV : ", getEnvByHost())
console.log("HOST : ", window.location.origin)
console.log("GET HOST : ", config[env].HOST)

const backofficeEnvConfig = {
  production: {
    HOST: "https://flex-backoffice.dentsu-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.dentsu-uat.zennite.co/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
  },
  uat: {
    HOST: "https://flex-backoffice.dentsu-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.dentsu-uat.zennite.co/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
  },
  staging: {
    HOST: "https://flex-backoffice.dentsu-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.dentsu-uat.zennite.co/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
  },
  development: {
    HOST: "https://flex-backoffice.colima.localhost",
    API_ENDPOINT: "https://flex-api.colima.localhost/graphql",
    API: "https://flex-api.colima.localhost",
  },
  development2: {
    HOST: "https://flex-backoffice.colima.localhost",
    API_ENDPOINT: "https://flex-api.colima.localhost/graphql",
    API: "https://flex-api.colima.localhost",
  },
}

export const backofficeEnv = backofficeEnvConfig[env]

export default config[env]