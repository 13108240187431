import { Modal } from "components"
import withHooks from "./withHooks"
import Theme from "theme/custom"
import { history } from "components/common/BrowserRouter"

const withPreventLeaveDirtyForm = (dirtyModalProps) =>
  withHooks((props, hooks) => {
    const { dirty, preventLeaveDirtyForm } = props
    const {
      title,
      children,
      okButtonLabel,
      okButtonVariant,
      okButtonColor,
      cancelButtonLabel,
      cancelButtonVariant,
      cancelButtonColor,
      headerCloseButton,
      buttonHeight,
    } = dirtyModalProps
    const { useEffect, useRef, useCallback, useTranslation } = hooks
    const { t } = useTranslation()

    const preventDirtyInfo = useRef(
      {
        dirty,
        enabled: preventLeaveDirtyForm || true,
        t: t,
      },
      [],
    )

    preventDirtyInfo.current = {
      ...preventDirtyInfo.current,
      dirty,
    }
    // useEffect(() => {}, [preventDirtyInfo, dirty]);

    const enablePreventLeaveDirtyForm = useCallback(() => {
      preventDirtyInfo.current = {
        ...preventDirtyInfo.current,
        enabled: true,
      }
    }, [])

    const disablePreventLeaveDirtyForm = useCallback(() => {
      preventDirtyInfo.current = {
        ...preventDirtyInfo.current,
        enabled: false,
      }
    }, [])

    const preventDirtyForm = useCallback((e) => {
      const { enabled, dirty } = preventDirtyInfo.current
      if (enabled && dirty) {
        e.preventDefault()
        return (e.returnValue = "")
      }
    }, [])

    useEffect(() => {
      window.addEventListener("beforeunload", preventDirtyForm)
      return () => {
        window.removeEventListener("beforeunload", preventDirtyForm)
      }
    }, [preventDirtyForm])

    useEffect(() => {
      const unblock = history.block((location) => {
        const { enabled, dirty, t } = preventDirtyInfo.current
        if (enabled && dirty) {
          Modal.confirm({
            headerCloseButton: headerCloseButton,
            buttonHeight: buttonHeight,
            title: title ? title : t("client.enhancers.withPreventLeaveDirtyForm.cancelEdit"),
            children: children ? children : t("client.enhancers.withPreventLeaveDirtyForm.cancelConfirm"),

            onOk: async ({ close }) => {
              await close()
              unblock()
              history.push(location)
            },

            okButtonLabel: okButtonLabel ? okButtonLabel : t("client.enhancers.withPreventLeaveDirtyForm.canceledEdit"),
            okButtonColor: okButtonColor ? okButtonColor : "default",
            okButtonVariant: okButtonVariant ? okButtonVariant : "contained",

            cancelButtonLabel: cancelButtonLabel
              ? cancelButtonLabel
              : t("client.enhancers.withPreventLeaveDirtyForm.close"),
            cancelButtonColor: cancelButtonColor ? cancelButtonColor : "default",
            cancelButtonVariant: cancelButtonVariant ? cancelButtonVariant : "outlined",
          })
          return false
        }
      })

      return () => {
        unblock()
      }
    }, [])

    return {
      ...props,
      enablePreventLeaveDirtyForm,
      disablePreventLeaveDirtyForm,
    }
  })

export default withPreventLeaveDirtyForm
