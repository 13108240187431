import { InsuranceSelectorComponent, InsuranceSelectorProps, Insurance } from "../InsuranceSelector"
import { compose, withHooks, withTranslation, withStores } from "enhancers"
import { ReactComponent as EditLineIcon } from "assets/icon/edit_line_icon.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { Box, Modal } from "components"
import Typography from "components/common/Typography"
import { paths, toCurrency } from "utils/helper"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import Theme from "theme/custom"
const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
    benefitInsuranceRes: stores.benefitInsuranceStore.benefitInsuranceRes,
  })),
  withTranslation({ prefix: "pages.main.benefit.InsuranceSelector" }),
  withHooks(
    (props: any, hooks: any): Omit<InsuranceSelectorProps, "t"> => {
      const { useState, useCallback, useParams, useMemo, useEffect, useDataTranslation, useUrlParam } = hooks
      const { t, selectedBenefitInsurancePackage, benefitInsuranceRes } = props
      const { id } = useParams()
      const { masterInsurancePackageId } = useUrlParam()
      const { masterBenefitInsurances } = benefitInsuranceRes || {}
      const { masterBenefitInsurancePackages } = masterBenefitInsurances?.[0] || {}

      const insurancePackages = useMemo(() => {
        if (masterBenefitInsurancePackages) {
          const result = masterBenefitInsurancePackages.map(({ masterInsurancePackage }: any) => {
            let description = ""
            let requiredPoints = 0
            for (const insurancePackagesInsurancePlan of masterInsurancePackage.insurancePackagesInsurancePlans) {
              description += insurancePackagesInsurancePlan.insurance.description
              requiredPoints += Number(insurancePackagesInsurancePlan.insurance.premium)
            }
            return {
              id: masterInsurancePackage.id,
              title: masterInsurancePackage.name,
              point: requiredPoints,
              description,
            }
          })
          return result
        }
        return []
      }, [masterBenefitInsurancePackages])

      const [selected, setSelected] = useState("")

      // MOCK
      const insuranceType = {
        title: t(".package"),
      }

      const handleSelect = useCallback(
        (insurance: Insurance) => {
          Modal.open({
            children: (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ flexDirection: "column" }}>
                <EditLineIcon
                  width={50}
                  height={50}
                  style={{ margin: 40 }}
                  fill={Theme.colors["Primary/Primary Text"]}
                />
                <Typography variant="Header/16">{t(".selectInsurancePlan")}</Typography>
                <br />
                <Typography variant="Body/14">{t(".needToSelect", { name: insurance.title })}</Typography>
                <Box display="flex" alignItems="center">
                  <PointCoinIcon style={{ marginRight: "8px" }} />
                  <Typography variant="Header/20">{`${toCurrency(insurance.point, { minimumFractionDigits: 0 })} ${t(
                    ".point",
                  )}`}</Typography>
                </Box>
              </Box>
            ),
            onOk: ({ close }: any) => {
              setSelected(insurance.id)
              if (masterBenefitInsurancePackages) {
                close()
                const selectedPackage = masterBenefitInsurancePackages.find(
                  ({ masterInsurancePackage }: any) => masterInsurancePackage.id === insurance.id,
                )
                if (selectedPackage) {
                  const { masterInsurancePackage } = selectedPackage
                  let requiredPoint = 0
                  masterInsurancePackage.insurancePackagesInsurancePlans.forEach((plan: any) => {
                    requiredPoint += Number(plan.insurance.premium)
                  })

                  benefitInsuranceStore.setSelectedBenefitInsurancePackage({
                    id: masterInsurancePackage.id,
                    masterInsurancePackage,
                    point: requiredPoint,
                  })
                }
              }
            },
            okButtonVariant: "contained",
            cancelButtonVariant: "outlined",
          })
        },
        [t, masterBenefitInsurancePackages],
      )

      const handleClickBack = useCallback(() => {
        paths.previewBenefitPackagePath(id).push()
      }, [id])

      useEffect(() => {
        if (selectedBenefitInsurancePackage) {
          setSelected(selectedBenefitInsurancePackage.id)
        } else if (masterBenefitInsurancePackages) {
          const selectedPackage = masterBenefitInsurancePackages.find(
            ({ masterInsurancePackage }: any) => masterInsurancePackage.id === masterInsurancePackageId,
          )
          if (selectedPackage) {
            const { masterInsurancePackage } = selectedPackage
            let requiredPoint = 0
            masterInsurancePackage.insurancePackagesInsurancePlans.forEach((plan: any) => {
              requiredPoint += Number(plan.insurance.premium)
            })
            benefitInsuranceStore.setSelectedBenefitInsurancePackage({
              id: masterInsurancePackage.id,
              masterInsurancePackage,
              point: requiredPoint,
            })
          }
        }
      }, [selectedBenefitInsurancePackage, masterInsurancePackageId, masterBenefitInsurancePackages])

      return { handleClickBack, handleSelect, insurances: insurancePackages, selected, header: insuranceType.title }
    },
  ),
)

export const PreviewPackageSelectorPage = enhancer(InsuranceSelectorComponent)
