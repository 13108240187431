import DefaultColors from "./default-colors"
import SCGCConfigs from "./scgc-colors"

const appColorConfigs = {
  default: DefaultColors,
  "บริษัทเอสซีจี เคมิคอลส์ จำกัด (มหาชน)": SCGCConfigs,
  SCGC: SCGCConfigs,
  "SCGC-Family": SCGCConfigs,
}

class CustomTheme {
  get icons() {
    const themeName = (): keyof typeof appColorConfigs => {
      const themeName = localStorage.getItem("themeName")
      const userDepartment = (themeName as keyof typeof appColorConfigs) || "default"

      const colorConfigsName = userDepartment in appColorConfigs ? userDepartment : "default"

      return colorConfigsName
    }

    return appColorConfigs[themeName()]?.icons || appColorConfigs.default.icons
  }

  get colors() {
    const themeName = (): keyof typeof appColorConfigs => {
      const themeName = localStorage.getItem("themeName")
      const userDepartment = (themeName as keyof typeof appColorConfigs) || "default"

      const colorConfigsName = userDepartment in appColorConfigs ? userDepartment : "default"

      return colorConfigsName
    }
    return appColorConfigs[themeName()]?.colors || appColorConfigs.default.colors
  }

  get backgroundGradient() {
    const themeName = (): keyof typeof appColorConfigs => {
      const themeName = localStorage.getItem("themeName")
      const userDepartment = (themeName as keyof typeof appColorConfigs) || "default"

      const colorConfigsName = userDepartment in appColorConfigs ? userDepartment : "default"

      return colorConfigsName
    }

    return appColorConfigs[themeName()]?.gradient || appColorConfigs.default.gradient
  }
}

export default new CustomTheme()
